const config = {
    baseURL:'http://192.168.0.7:10001',
    formatDate(d, format) {
      if(d>0){
          let date = ''
          if (d) {
              d = d + ''
              if (d.length < 13) {
                  date = new Date(Number(d) * 1000)
              } else {
                  date = new Date(Number(d))
              }
          } else {
              date = new Date()
          }
          const o = {
              'M+': date.getMonth() + 1,
              'd+': date.getDate(),
              'H+': date.getHours(),
              'm+': date.getMinutes(),
              's+': date.getSeconds(),
              'q+': Math.floor((date.getMonth() + 3) / 3),
              'S': date.getMilliseconds()
          }
          if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1
              .length))
          for (let k in o) {
              if (new RegExp('(' + k + ')').test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length ===
                  1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
          }
          return format
      }else {
          return ''
      }
    },
    formatNumber(data) {
        if (data || data === 0) {
            let num = data * 1
            return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        } else {
            return ''
        }
    },
    formatSYNum(data, data2) {
        if (Number(data) > 0 && Number(data2) < 0) {
            return '0.00'
        } else {
            let num = data2 * 1
            return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        }
    },
    dateTime(fmt) {
        const date = new Date()
        const o = {
            'Y+': date.getFullYear(),
            'M+': date.getMonth() + 1, // 月
            'D+': date.getDate(), // 日
            'h+': date.getHours(), // 时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            W: date.getDay() // 周
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, () => {
                    if (k === 'W') {
                        // 星期几
                        const week = ['日', '一', '二', '三', '四', '五', '六']
                        return week[o[k]]
                    } else if (k === 'Y+' || RegExp.$1.length === 1) {
                        // 年份 or 小于10不加0
                        return o[k]
                    } else {
                        return ('00' + o[k]).substr(('' + o[k]).length) // 小于10补位0
                    }
                })
            }
        }
        return fmt
    }

}
export default {
    install: function (Vue) {
        Vue.prototype.$config = config
    },
    ...config
}
