import {get, post} from '../request';

export const findInsuranceList = (params) => post(`/api/v1/admin/home/findInsuranceList`,params)

//查看医保
export const getInsurancePolicy = (params) => get(`/api/v1/admin/home/getInsurancePolicy/${params}`)

//重置DiD
export const insuranceResetDID = (params) => get(`/api/v1/admin/home/insuranceResetDID/${params}`)

//添加
export const addInsurance = (params) => post(`/api/v1/admin/home/addInsurance`,params)

//编辑
export const updateInsurance = (params) => post(`/api/v1/admin/home/updateInsurance`,params)

//删除
export const deleteInsurance = (params) => get(`/api/v1/admin/home/deleteInsurance/${params}`)
