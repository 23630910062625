import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch((err) => err);
};
const routes = [{
    path: '/',
    redirect: '/login'
},
    {
        path: '/Login',
        name: 'Login',
        component: () => import( /* webpackChunkName: "login" */ '../views/login.vue'),
    },
    {
        path: '/page',
        name: 'page',
        component: () => import( /* webpackChunkName: "login" */ '../views/page.vue'),
        children:[
            {
                path: '/staff',
                name: 'staff',
                component: () => import( /* webpackChunkName: "login" */ '../views/staff/index.vue'),
            },
            {
                path: '/insurance',
                name: 'insurance',
                component: () => import( /* webpackChunkName: "login" */ '../views/insurance/index.vue'),
            },
            {
                path: '/doctor',
                name: 'doctor',
                component: () => import( /* webpackChunkName: "login" */ '../views/doctor/index.vue'),
            },
            {
                path: '/approval',
                name: 'approval',
                component: () => import( /* webpackChunkName: "login" */ '../views/Approval/index.vue'),
            },
            {
                path: '/details',
                name: 'details',
                component: () => import( /* webpackChunkName: "login" */ '../views/Approval/details.vue'),
            },
        ]
    },
]

const router = new VueRouter({
    routes
})

export default router
